import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  close() {
    // Adding a setTimeout for closing on click so as not to close the dropdown immediately
    setTimeout(() => {
      this.toggle();
    }, 500);
  }

  toggle() {
    const toggleClass = this.data.get('toggleClass');
    this.element.classList.toggle(toggleClass);
  }

  handleClickOutside(event) {
    const dropdown = this.element;
    const toggleClass = this.data.get('toggleClass');

    if (!dropdown.contains(event.target)) {
      this.element.classList.remove(toggleClass);
    }
  }
}
